// import { useEffect, useRef, useState } from "react";
import logo from "./logo3.png";
// import axios from 'axios';
// import useRazorpay from "react-razorpay";
// import './style.css';
function FormMain() {
    return (
        <>

            <nav className="navbar navbar-expand-lg bg-body-tertiary">
                <div className="container">
                    <a className="navbar-brand me-2" href="https://vortexictmumbai.com/">
                        <img
                            src={logo}
                            height={50}
                            alt="MDB Logo"
                            loading="lazy"
                            style={{ marginTop: "-1px" }}
                        />
                    </a>
                    <button
                        data-mdb-collapse-init=""
                        className="navbar-toggler"
                        type="button"
                        data-mdb-target="#navbarButtonsExample"
                        aria-controls="navbarButtonsExample"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <i className="fas fa-bars" />
                    </button>
                    <div className="collapse navbar-collapse" id="navbarButtonsExample">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <a className="nav-link" href="#">
                                    VORTEX ICT 2024 EVENT REGISTRATION.
                                </a>
                            </li>
                        </ul>
                        <div className="d-flex align-items-center">
                            {/* <a
                                data-mdb-ripple-init=""
                                type="button"
                                className="btn btn-link px-3 me-2"
                                href="https://login.vortexictmumbai.com/"
                            >
                                Login
                            </a> */}
                            <a href="https://login.vortexictmumbai.com/">
                            <button
                                data-mdb-ripple-init=""
                                type="button"
                                className="btn btn-primary me-3"
                            >
                                Login
                            </button>
                            </a>
                        </div>
                    </div>
                </div>
            </nav>
            {/* Navbar */}
<br/>
            <div className='container'>
                <form className="row g-3">
                <div className="col-md-12">
                        <label htmlFor="inputName" className="form-label">
                        Enter Full Name
                        </label>
                        <input type="text" className="form-control" id="inputName" />
                    </div>

                    <div className="col-md-6">
                        <label htmlFor="inputEmail4" className="form-label">
                            Email
                        </label>
                        <input type="email" className="form-control" id="inputEmail4" />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="inputNumber" className="form-label">
                        Phone No
                        </label>
                        <input type="text" className="form-control" id="inputNumber" />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="inputName" className="form-label">
                        Age
                        </label>
                        <input type="text" className="form-control" id="inputPassword4" />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="inputGender" className="form-label">
                            Gender
                        </label>
                        <select id="inputGender" className="form-select">
                            <option selected="">Choose...</option>
                            <option>Male</option>
                            <option>Female</option>
                            <option>Other</option>
                        </select>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="inputRollNo" className="form-label">
                        Roll No.
                        </label>
                        <input type="text" className="form-control" id="inputRollNo" />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="inputCName" className="form-label">
                        Collage Name
                        </label>
                        <input type="text" className="form-control" id="inputCName" />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="inputCName" className="form-label">
                        Collage Name
                        </label>
                        <input type="text" className="form-control" id="inputCName" />
                    </div>
                    
                    
                    <div className="col-md-6">
                        <label htmlFor="inputCity" className="form-label">
                            City
                        </label>
                        <input type="text" className="form-control" id="inputCity" />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="inputState" className="form-label">
                            State
                        </label>
                        <select id="inputState" className="form-select">
                            <option selected="">Choose...</option>
                            <option>...</option>
                        </select>
                    </div>
                    <div className="col-md-2">
                        <label htmlFor="inputZip" className="form-label">
                            Zip
                        </label>
                        <input type="text" className="form-control" id="inputZip" />
                    </div>
                    <div className="col-12">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="gridCheck" />
                            <label className="form-check-label" htmlFor="gridCheck">
                                Check me out
                            </label>
                        </div>
                    </div>
                    <div className="col-12">
                        <button type="submit" className="btn btn-primary">
                            Sign in
                        </button>
                    </div>
                </form>
            </div>
        </>

    );
}

export default FormMain;