import { useEffect, useRef, useState } from "react";
import logo from "./logo3.png";
import axios from 'axios';
import useRazorpay from "react-razorpay";

function App() {

  const [GetFullName,SetFullName] = useState();
  const [GetEmail, SetEmail] = useState();
  const [GetPhoneNo, SetPhoneNo] = useState();
  const [GetAge, SetAge] = useState();
  const [GetGender, SetGender] = useState();
  const [GetCollageName, SetCollageName] = useState();
  const [GetRollNo, SetRollNo] = useState();
  const [GetCity, SetCity] = useState();
  const [GetState, SetState] = useState();
  const [GetCoupon, SetCoupon] = useState();
  const [getOption, setOptions] = useState();
  // Razorpay Details

  const [Razorpay] = useRazorpay();




  const HandlePaymentCallBackSuccess = (razorpay_payment_id,razorpay_order_id,razorpay_signature,amount) => { 
    const formdata = new FormData();
    formdata.append("razorpay_payment_id", razorpay_payment_id);
    formdata.append("razorpay_order_id", razorpay_order_id);
    formdata.append("razorpay_signature", razorpay_signature);
    formdata.append("amount", amount);
    axios.post("https://vortexictmumbai.com/razmainreg/PayMentStateUp.php", formdata).then(response => {
      if(response.data[0].status === true){
        alert(response.data[0].message);
        window.location.reload();
      }else{
        alert("Payment Failed if Money Deducted Contact Admin")
      }
    }).catch(err =>{
      console.log(err);
    })
  }




  const handlePayment = async (orderid, amount, currency) => {
    
  
    const options = {
      key: "rzp_live_gwEAT74AB6AMML", // Enter the Key ID generated from the Dashboard
      amount: amount.toString(), // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: currency,
      name: GetFullName,
      description: "Vortex 11.0x Registration",
      image: "https://pay.vortexictmumbai.com/logo512.png",
      order_id: orderid.toString(), //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
      handler: function (response) {
        HandlePaymentCallBackSuccess(response.razorpay_payment_id,response.razorpay_order_id, response.razorpay_signature, amount);
        console.log(response);
        // alert(response.razorpay_payment_id);
        // alert(response.razorpay_order_id);
        // alert(response.razorpay_signature);
      },
      prefill: {
        name: GetFullName,
        email: GetEmail,
        contact: "+91 "+GetPhoneNo,
      },
      notes: {
        address: GetState,
      },
      theme: {
        color: "#3399cc",
      },
    };
  


      
      const rzp1 = new Razorpay(options);
      rzp1.open();
      rzp1.on("payment.failed", function (response) {
        alert(response.error.code);
        alert(response.error.description);
        alert(response.error.source);
        alert(response.error.step);
        alert(response.error.reason);
        alert(response.error.metadata.order_id);
        alert(response.error.metadata.payment_id);
      });
  

  
   
};
  


  const HandleFormSubmit = (e) => { 
    e.preventDefault();
    if(Boolean(GetFullName) && Boolean(GetEmail) && Boolean(GetPhoneNo) && Boolean(GetAge) && Boolean(GetGender) && Boolean(GetCollageName) && Boolean(GetRollNo) && Boolean(GetCity) && Boolean(GetState)) {
      const formdata = new FormData();
      formdata.append("fullname", GetFullName);
      formdata.append("email", GetEmail);
      formdata.append("phone", GetPhoneNo);
      formdata.append("age", GetAge);
      formdata.append("gender",GetGender);
      formdata.append("collage", GetCollageName);
      formdata.append("RollNo", GetRollNo);
      formdata.append("city",GetCity);
      formdata.append("state",GetState);
      formdata.append("couponcode", GetCoupon ? GetCoupon : "");

    axios.post("https://vortexictmumbai.com/razmainreg/PayB.php", formdata).then(response => { 
      // window.location.replace(response.data);
      console.log(response.data[0])
      if(response.data[0].status === true){
        const ArrayData = response.data[0].message ? response.data[0].message[0] : null ;
if(Boolean(ArrayData)){
  handlePayment(ArrayData['id'], ArrayData['amount'], ArrayData['currency'])
}

       
      }else{
        alert("Something Went Wrong");
      }


    }).catch(err => { 
      console.log(err);
    })

    }else 
    {
      alert("Please Fill All Fields");
    }
  }

  return (
    <>
    <>
  {/* Navbar */}
  <nav className="navbar navbar-expand-lg bg-body-tertiary">
    {/* Container wrapper */}
    <div className="container">
      {/* Navbar brand */}
      <a className="navbar-brand me-2" href="https://vortexictmumbai.com/">
        <img
          src={logo}
          height={50}
          alt="MDB Logo"
          loading="lazy"
          style={{ marginTop: "-1px" }}
        />
      </a>
      {/* Toggle button */}
      <button
        data-mdb-collapse-init=""
        className="navbar-toggler"
        type="button"
        data-mdb-target="#navbarButtonsExample"
        aria-controls="navbarButtonsExample"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <i className="fas fa-bars" />
      </button>
      {/* Collapsible wrapper */}
      <div className="collapse navbar-collapse" id="navbarButtonsExample">
        {/* Left links */}
        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
          <li className="nav-item">
            <a className="nav-link" href="#">
            VORTEX ICT 2024 EVENT REGISTRATION.
            </a>
          </li>
        </ul>
        {/* Left links */}
        <div className="d-flex align-items-center">
          <a
            data-mdb-ripple-init=""
            type="button"
            className="btn btn-link px-3 me-2"
            href="https://login.vortexictmumbai.com/"
          >
            Login
          </a>
          <button
            data-mdb-ripple-init=""
            type="button"
            className="btn btn-primary me-3"
          >
            Sign up
          </button>
          {/* <a
            data-mdb-ripple-init=""
            className="btn btn-dark px-3"
            href="https://github.com/mdbootstrap/mdb-ui-kit"
            role="button"
          >
            <i className="fab fa-github" />
          </a> */}
        </div>
      </div>
      {/* Collapsible wrapper */}
    </div>
    {/* Container wrapper */}
  </nav>
  {/* Navbar */}
</>


    <div className="container">
<br/>
<form onSubmit={HandleFormSubmit}>
  {/* Name input */}
 
  <label className="form-label" htmlFor="form1Example1">
      Enter Full Name
    </label>
    <input type="text"  className="form-control" onChange={(e) => { 
      SetFullName(e.target.value);
    }} />
 
 
  {/* Password input */}
 
  <label className="form-label" htmlFor="form1Example2">
      Email Address
    </label>
    <input type="email" className="form-control" onChange={(e) => { 
      SetEmail(e.target.value);
    }} />
 

  <label className="form-label" htmlFor="form1Example2" onChange={(e) => { 
      SetPhoneNo(e.target.value);
    }}>
      Phone No
    </label>
    <input type="text" className="form-control" onChange={(e) => { 
      SetPhoneNo(e.target.value);
    }} />

 


 
  <label className="form-label" htmlFor="form1Example2">
      Age
    </label>
    <input type="number" className="form-control" onChange={(e) => { 
      SetAge(e.target.value);
    }} />

<label className="form-label" htmlFor="form1Example2">
      Select Gender
    </label>
<select className="form-select" aria-label="Default select example" onChange={(e) => { 
   SetGender(e.target.value)
}} placeholder="Select Gender">
  <option value="male">Select...</option>
  <option value="male">Male</option>
  <option value="female">Female</option>
  <option value="other">Other</option>
</select>
  

<br/>



  <label className="form-label" htmlFor="form1Example2"> Collage Name  </label>
    <input type="text" className="form-control" onChange={(e) => {
      SetCollageName(e.target.value);
    }} />
   
     
  
   <label className="form-label" htmlFor="form1Example2">
      Roll No
    </label>

  
    <input type="text" className="form-control" onChange={(e) => {
      SetRollNo(e.target.value);
    }} />
    


    <label className="form-label" htmlFor="form1Example2">
     City
    </label>
 
    <input type="text" className="form-control" onChange={(e)=>{
      SetCity(e.target.value);
    }} />



  <label className="form-label" htmlFor="form1Example2">
      State
    </label>
    <input type="text" className="form-control" onChange={(e) => {
      SetState(e.target.value);
    }} />



  <label className="form-label" htmlFor="form1Example2">
      Coupon Code
    </label>
    <input type="text" className="form-control" onChange={(e) => {
      SetCoupon(e.target.value);
    }} />


  {/* 2 column grid layout for inline styling */}
  {/* <div className="row mb-4">
    <div className="col d-flex justify-content-center">
    
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          defaultValue=""
          id="form1Example3"
          defaultChecked=""
        />
        <label className="form-check-label" htmlFor="form1Example3">
          
          Remember me
        </label>
      </div>
    </div>
  </div> */}
  {/* Submit button */}
  <br/>
  <button
    data-mdb-ripple-init=""
    type="submit"
    className="btn btn-primary btn-block"
  >
    Register
  </button>
</form>

    </div>
    </>
  );
}

export default App;
