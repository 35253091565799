import React from "react";
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from './App';
import FormMain from './formMain';


ReactDOM.render(
<BrowserRouter>
  <Routes>
    {/* <App /> */}
    <Route path="/" element={<App />}></Route>
    <Route path="/FormMain" element={<FormMain />}></Route>
    </Routes>
</BrowserRouter>
,
document.getElementById('root'))